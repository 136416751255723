import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {interval, Subscription} from 'rxjs';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    TIMEOUT_AFTER_FLIP_READY: number = 500;
    TIMEOUT_AFTER_LOADING_READY: number = 1200;

    images = [
        '/assets/diploma/Small-1.jpg',
        '/assets/diploma/Small-2.jpg',
        '/assets/diploma/Small-3.jpg',
        '/assets/diploma/Small-4.jpg',
        '/assets/diploma/Small-5.jpg',
        '/assets/diploma/Small-6.jpg',
        '/assets/diploma/Small-7.jpg',
        '/assets/diploma/Small-8.jpg',
        '/assets/diploma/Small-9.jpg',
        '/assets/diploma/Small-10.jpg',
        '/assets/diploma/Small-11.jpg',
        '/assets/diploma/Small-12.jpg',
        '/assets/diploma/Small-13.jpg',
        '/assets/diploma/Small-14.jpg',
        '/assets/diploma/Small-15.jpg',
        '/assets/diploma/Small-16.jpg',
        '/assets/diploma/Small-17.jpg',
        '/assets/diploma/Small-18.jpg',
        '/assets/diploma/Small-19.jpg',
        '/assets/diploma/Small-20.jpg',
        '/assets/diploma/Small-21.jpg',
        '/assets/diploma/Small-22.jpg',
        '/assets/diploma/Small-23.jpg',
        '/assets/diploma/Small-24.jpg',
        '/assets/diploma/Small-25.jpg',
        '/assets/diploma/Small-26.jpg',
        '/assets/diploma/Small-27.jpg',
        '/assets/diploma/Small-28.jpg',
        '/assets/diploma/Small-29.jpg',
        '/assets/diploma/Small-30.jpg',
        '/assets/diploma/Small-31.jpg',
        '/assets/diploma/Small-32.jpg',
        '/assets/diploma/Small-33.jpg',
        '/assets/diploma/Small-34.jpg',
        '/assets/diploma/Small-35.jpg',
        '/assets/diploma/Small-36.jpg',
        '/assets/diploma/Small-37.jpg',
        '/assets/diploma/Small-38.jpg',
        '/assets/diploma/Small-39.jpg',
        '/assets/diploma/Small-40.jpg',
        '/assets/diploma/Small-41.jpg',
        '/assets/diploma/Small-42.jpg',
        '/assets/diploma/Small-43.jpg',
        '/assets/diploma/Small-44.jpg',
        '/assets/diploma/Small-45.jpg',
        '/assets/diploma/Small-46.jpg',
        '/assets/diploma/Small-47.jpg',
        '/assets/diploma/Small-48.jpg',
        '/assets/diploma/Small-49.jpg',
        '/assets/diploma/Small-50.jpg',
        '/assets/diploma/Small-51.jpg',
        '/assets/diploma/Small-52.jpg',
        '/assets/diploma/Small-53.jpg',
        '/assets/diploma/Small-54.jpg',
        '/assets/diploma/Small-55.jpg',
        '/assets/diploma/Small-56.jpg',
        '/assets/diploma/Small-57.jpg',
        '/assets/diploma/Small-58.jpg',
        '/assets/diploma/Small-59.jpg',
        '/assets/diploma/Small-60.jpg',
        '/assets/diploma/Small-61.jpg',
        '/assets/diploma/Small-62.jpg',
        '/assets/diploma/Small-63.jpg',
        '/assets/diploma/Small-64.jpg',
        '/assets/diploma/Small-65.jpg',
        '/assets/diploma/Small-66.jpg',
        '/assets/diploma/Small-67.jpg',
        '/assets/diploma/Small-68.jpg',
        '/assets/diploma/Small-69.jpg',
        '/assets/diploma/Small-70.jpg',
        '/assets/diploma/Small-71.jpg',
        '/assets/diploma/Small-72.jpg',
        '/assets/diploma/Small-73.jpg',
        '/assets/diploma/Small-74.jpg',
        '/assets/diploma/Small-75.jpg',
        '/assets/diploma/Small-76.jpg',
        '/assets/diploma/Small-77.jpg',
        '/assets/diploma/Small-78.jpg',
        '/assets/diploma/Small-79.jpg',
        '/assets/diploma/Small-80.jpg',
        '/assets/diploma/Small-81.jpg',
        '/assets/diploma/Small-82.jpg',
        '/assets/diploma/Small-83.jpg',
        '/assets/diploma/Small-84.jpg',
        '/assets/diploma/Small-85.jpg',
        '/assets/diploma/Small-86.jpg',
        '/assets/diploma/Small-87.jpg',
        '/assets/diploma/Small-88.jpg',
        '/assets/diploma/Small-89.jpg',
        '/assets/diploma/Small-90.jpg',
        '/assets/diploma/Small-91.jpg',
        '/assets/diploma/Small-92.jpg',
        '/assets/diploma/Small-93.jpg',
        '/assets/diploma/Small-94.jpg',
        '/assets/diploma/Small-95.jpg',
        '/assets/diploma/Small-96.jpg',
        '/assets/diploma/Small-97.jpg',
        '/assets/diploma/Small-98.jpg',
        '/assets/diploma/Small-99.jpg',
        '/assets/diploma/Small-100.jpg',
        '/assets/diploma/Small-101.jpg',
        '/assets/diploma/Small-102.jpg',
        '/assets/diploma/Small-103.jpg',
        '/assets/diploma/Small-104.jpg',
        '/assets/diploma/Small-105.jpg',
        '/assets/diploma/Small-106.jpg',
        '/assets/diploma/Small-107.jpg',
        '/assets/diploma/Small-108.jpg',
        '/assets/diploma/Small-109.jpg',
        '/assets/diploma/Small-110.jpg',
        '/assets/diploma/Small-111.jpg',
        '/assets/diploma/Small-112.jpg',
        '/assets/diploma/Small-113.jpg',
        '/assets/diploma/Small-114.jpg',
        '/assets/diploma/Small-115.jpg',
        '/assets/diploma/Small-116.jpg',
        '/assets/diploma/Small-117.jpg',
        '/assets/diploma/Small-118.jpg',
        '/assets/diploma/Small-119.jpg',
        '/assets/diploma/Small-120.jpg',
        '/assets/diploma/Small-121.jpg',
        '/assets/diploma/Small-122.jpg',
        '/assets/diploma/Small-123.jpg',
        '/assets/diploma/Small-124.jpg',
        '/assets/diploma/Small-125.jpg',
        '/assets/diploma/Small-126.jpg',
        '/assets/diploma/Small-127.jpg',
        '/assets/diploma/Small-128.jpg',
        '/assets/diploma/Small-129.jpg',
        '/assets/diploma/Small-130.jpg',
        '/assets/diploma/Small-131.jpg',
        '/assets/diploma/Small-132.jpg',
        '/assets/diploma/Small-133.jpg',
        '/assets/diploma/Small-134.jpg',
        '/assets/diploma/Small-135.jpg',
        '/assets/diploma/Small-136.jpg',
        '/assets/diploma/Small-137.jpg',
        '/assets/diploma/Small-138.jpg',
        '/assets/diploma/Small-139.jpg',
        '/assets/diploma/Small-140.jpg',
        '/assets/diploma/Small-141.jpg',
        '/assets/diploma/Small-142.jpg',
        '/assets/diploma/Small-143.jpg',
        '/assets/diploma/Small-144.jpg',
        '/assets/diploma/Small-145.jpg',
        '/assets/diploma/Small-146.jpg',
        '/assets/diploma/Small-147.jpg',
        '/assets/diploma/Small-148.jpg',
        '/assets/diploma/Small-149.jpg',
        '/assets/diploma/Small-150.jpg',
        '/assets/diploma/Small-151.jpg',
        '/assets/diploma/Small-152.jpg',
        '/assets/diploma/Small-153.jpg',
        '/assets/diploma/Small-154.jpg',
        '/assets/diploma/Small-155.jpg',
        '/assets/diploma/Small-156.jpg',
        '/assets/diploma/Small-157.jpg',
        '/assets/diploma/Small-158.jpg',
        '/assets/diploma/Small-159.jpg',
        '/assets/diploma/Small-160.jpg',
        '/assets/diploma/Small-161.jpg',
        '/assets/diploma/Small-162.jpg',
        '/assets/diploma/Small-163.jpg',
        '/assets/diploma/Small-164.jpg',
        '/assets/diploma/Small-165.jpg',
        '/assets/diploma/Small-166.jpg',
        '/assets/diploma/Small-167.jpg',
    ];

    timer: any;
    loading: boolean = true;
    finished: boolean = false;
    totalLoaded: number = 0;
    year: number = (new Date()).getFullYear();

    constructor(private elem: ElementRef, private renderer: Renderer2) {
    }

    shuffle(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    ngOnInit(): void {
        let i = 0;
        this.timer = setInterval(x => {
            let loading = this.elem.nativeElement.querySelector('.loader');
            this.renderer.setStyle(loading, 'width', this.getWidht());
            console.log(i);

            i++;
        }, 10);
    }

    onReady() {
        setTimeout(()=>{
            this.loading = false;
            setTimeout(()=>{
                let elements = this.elem.nativeElement.querySelectorAll('.card');
                elements.forEach((e)=> {
                    this.renderer.addClass(e, 'flip');
                });
            }, this.TIMEOUT_AFTER_FLIP_READY);
        }, this.TIMEOUT_AFTER_LOADING_READY);
        clearInterval(this.timer);
    }

    getWidht() {
        return ((this.totalLoaded * 100) / this.images.length) +'%';
    }

    onLoad() {
        this.totalLoaded++;
        if (this.totalLoaded == this.images.length) {
            this.finished = true;
            this.onReady();
        }
    }

}
