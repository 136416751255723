<div class="container">
    <div class="overlay"></div>
    <div class="title">
        <h1 [ngClass]="{'slide-out-left': !loading}">Andris Fiļimonovs</h1>
        <h2 [ngClass]="{'slide-out-left': !loading}">Sporta Dzīves Meistars</h2>
        <div class="loading"><span class="loader" [ngClass]="{'w': finished}"></span></div>
        <h3 [ngClass]="{'slide-in-left': !loading}" style="opacity: 0">andris@filimonovs.com</h3>
    </div>
    <div class="footer">© Copyright {{ year }} Filimonovs</div>
    <div *ngFor="let img of this.shuffle(images); let i = index" class="cards">
        <div class="card">
            <div class="card-front"></div>
            <div class="card-back" [ngStyle]="{'background-image': 'url('+img+')'}" appBackgroundLoader (imageLoaded)="onLoad()"></div>
        </div>
    </div>
</div>
