import {Directive, Output, EventEmitter, ElementRef, AfterViewInit, Input, Renderer2} from '@angular/core';

@Directive({
    selector: '[appBackgroundLoader]'
})
export class BackgroundLoaderDirective implements AfterViewInit {
    @Output() imageLoaded: EventEmitter<boolean> = new EventEmitter<boolean>(true);

    constructor(private el: ElementRef, private render: Renderer2) {
    }

    ngAfterViewInit() {
        const img = new Image();
        const bgStyle = getComputedStyle(this.el.nativeElement).backgroundImage;
        const src = bgStyle.replace(/(^url\()|(\)$|[\"\'])/g, '');
        img.src = src;
        img.addEventListener('load', () => {
            this.imageLoaded.emit(true);
        });
    }
}
